<template lang="html">
    <div class="container inputOutput">
        <top-navigation-bar
            title="Two Means"
            right-nav="reset"
            @click="doReset"
        />
        <div class="m-view mt-3">
            <h2>H<sub>0</sub> : μ<sub>1</sub> - μ<sub>2</sub> = 0</h2>
        </div>
        <div class="m-view d-flex">
            <h2>H<sub>A</sub> : μ<sub>1</sub> - μ<sub>2</sub> {{ getSign }} 0</h2>
        </div>

        <div v-if="isCompDependent">
            <button
                class="action-btn"
                @click="showPairedDifferenceModal"
            >
                Import Datasets
            </button>

            <input-single
                v-model="n"
                :label="{text: 'Sample Size', mathChar: 'n'}"
                style-class="input-single"
            />
            <input-single
                v-model="dBar"
                :label="{text: 'Mean', mathChar: 'd̄', helpIcon: true}"
                style-class="input-single"
                @icon-click="showHelpModal()"
            />
            <input-single
                v-model="sd"
                :label="{text: 'Std Dev', mathChar: 'S', subScript: 'd'}"
                style-class="input-single"
            />

            <PairedDifferenceModal
                ref="modalPairedDifference"
                select-two-error-message="
                    Both sample sizes must be n ≥ 2 and must be equal
                "
                @select="importPairedDifference"
            />
        </div>

        <div
            v-if="!isCompDependent"
        >
            <button
                class="action-btn"
                @click="showSelectModal"
            >
                Import Datasets
            </button>

            <div class="inline-input-view multi d-flex">
                <input-single
                    v-model="n1"
                    label="𝒏₁"
                    style-class="input-single group"
                    group
                />
                <input-single
                    v-model="n2"
                    label="𝒏₂"
                    style-class="input-single group"
                    group
                />
            </div>
            <div class="inline-input-view multi d-flex">
                <input-single
                    v-model="x̄1"
                    label="𝒙̄₁"
                    style-class="input-single group"
                    group
                />
                <input-single
                    v-model="x̄2"
                    label="𝒙̄₂"
                    style-class="input-single group"
                    group
                />
            </div>
            <div class="inline-input-view multi d-flex">
                <input-single
                    v-model="s1"
                    label="𝐬₁"
                    style-class="input-single group"
                    group
                />
                <input-single
                    v-model="s2"
                    label="𝐬₂"
                    style-class="input-single group"
                    group
                />
            </div>

            <SelectTwoDatasetsModal
                ref="modalSelect"
                :min-sample-size="selectMinSampleSize"
                :verify="selectVerify"
                :error-message="selectErrorMessage"
                :filter-second="selectFilterSecond"
                @select="importDatasets"
            />
        </div>

        <div class="sticky-bottom m-3 mt-5">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Calculate
            </button>
        </div>

        <modal
            ref="modalOutput"
            title="Two Means - Result"
            modal-type="hypothesis"
        >
            <template #formula>
                <div class="m-view">
                    <h2>H<sub>0</sub> : μ<sub>1</sub> - μ<sub>2</sub> = 0</h2>
                </div>
                <div class="m-view d-flex mb-2">
                    <h2>H<sub>A</sub> : μ<sub>1</sub> - μ<sub>2</sub> {{ getSign }} 0</h2>
                </div>
            </template>
            <template #image>
                <div class="m-view d-flex justify-content-start row">
                    <img
                        class="formulaImage-lg"
                        alt="hypothesis testing formula"
                        :src="require(`@/assets/HTformulaImgs/${getComputationName}.jpg`)"
                    >
                    <h3 class="formulaImage-text">
                        = {{ z }}
                    </h3>
                    <h3
                        v-if="df"
                        class="formulaImage-df"
                    >
                        df = {{ df }}
                    </h3>
                </div>
                <div class="formula-btn">
                    <button
                        v-if="getComputationName === 'TwoISmallNotEqual'"
                        class="action-btn"
                        @click="showDfFormulaModel()"
                    >
                        See df Formula
                    </button>
                </div>
            </template>
            <template #graph>
                <h4 class="mb-3 text-center">
                    p-Value = {{ notEqualPdisplay }}P({{ charCompDisplay }} {{ symbolPdisplay }} {{ z }}) = {{ prob }}
                </h4>
                <highcharts
                    class="m-auto"
                    :options="hypothGraph.chartOptions"
                />
            </template>
            <template #conclusion>
                <h3 class="mb-3 text-center">
                    Conclusion:
                </h3>
                <h5 class="mb-3 text-center">
                    There is a {{ probDisplay }}% chance of concluding <br> μ<sub>1</sub> {{ getSign }} μ<sub>2</sub> and being wrong
                </h5>
            </template>
        </modal>
        <modal
            ref="modalFormula"
            title="Formula"
            style-class="modal-bottom"
            modal-type="general"
        >
            <template #body>
                <img
                    class="formulaImage"
                    alt="binomial formula"
                    src="@/assets/helpImgs/popUpWelchs.png"
                >
            </template>
        </modal>
        <modal
            ref="modalHelp"
            title="Help"
            style-class="modal-bottom"
            modal-type="general"
        >
            <template #body>
                <img
                    class="formulaImage"
                    alt="example paired differences"
                    src="@/assets/helpImgs/popUpDependent.png"
                >
            </template>
        </modal>
    </div>
</template>

<script>
import InputSingle from '@/components/input-single.vue'
import Modal from '@/components/modal.vue'
import PairedDifferenceModal from '@/components/paired-difference-modal.vue'
import SelectTwoDatasetsModal from '@/components/select-two-datasets-modal.vue'
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import normalData from '@/js/distributions/normal-data.js'
import hypothesisData from '@/js/hypothesis/hypothesis-data.js'
import { clearData } from '@/js/lib/input-check.js'
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'TwoMeanInput',
    components: {
        TopNavigationBar,
        highcharts: Chart,
        InputSingle,
        Modal,
        PairedDifferenceModal,
        SelectTwoDatasetsModal,
    },
    data () {
        return {
            allData: {},
            // TwoILarge
            n: '',
            dBar: '',
            sd: '',
            // Other screens
            x̄1: '',
            x̄2: '',
            n1: '',
            n2: '',
            s1: '',
            s2: '',
            // Output
            μ0: '', // Assumed value for μ in H𝗈
            z: '',
            df: '',
            prob: '',
            graphData: [],
            choiceData: [], // Local store
        }
    },
    computed: {
        notEqualPdisplay () {
            const output = this.getSign === '≠' ? '2' : ''
            return output
        },
        symbolPdisplay () {
            var value = this.getSign
            if (value !== '≠') {
                return value
            } else {
                const symbol = this.z >= 0 ? '>' : '<'
                return symbol
            }
        },
        charCompDisplay () {
            const df = _.get(this, 'df', '')
            return (df ? 't' : 'z')
        },
        probDisplay () {
            var prob = _.get(this, 'prob', '')
            const output = Number((prob * 100).toFixed(2))
            return output
        },
        getSign () {
            return _.get(this.choiceData.selected, 'value', '')
        },
        getComputationName () {
            return _.get(this.choiceData, 'computationPicked', '')
        },
        isCompDependent () {
            var comp = this.getComputationName
            return (comp === 'TwoDLarge' || comp === 'TwoDSmall')
        },
        hypothGraph () {
            return hypothesisData.graph(this.graphData)
        },
        selectMinSampleSize () {
            if (this.choiceData.pick.sample === 'large') {
                return 30
            }
            return undefined
        },
        selectErrorMessage () {
            if (this.choiceData.pick.sample === 'small') {
                return 'Both sample sizes must be n ≥ 2 and at least one ' +
                    'must be n < 30'
            }
            return undefined
        },
    },
    beforeMount () {
        var storeChoice = localStore.getStore('hypothesis-data-choice').choice
        var store = localStore.getStore('hypothesis-data') || ''
        if (storeChoice) {
            this.choiceData = storeChoice
            _.assign(this, { allData: store })
            var formulaStore = _.get(store, `${storeChoice.computationPicked}`, '')
            if (formulaStore) {
                _.assign(this, formulaStore.input)
            }
        }
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['x̄1', 'x̄2', 'n1', 'n2', 's1', 's2', 'n', 'dBar', 'sd']))
        },
        showModal () {
            this.$refs.modalOutput.show()
        },
        showDfFormulaModel () {
            this.$refs.modalFormula.show()
        },
        showHelpModal () {
            this.$refs.modalHelp.show()
        },
        showPairedDifferenceModal () {
            this.$refs.modalPairedDifference.show()
        },
        showSelectModal () {
            this.$refs.modalSelect.show()
        },
        updateCompType () {
            if (this.isCompDependent) {
                var computationPicked = 'TwoDSmall'
                if (this.n >= 30) {
                    computationPicked = 'TwoDLarge'
                }
                _.assign(this.choiceData, { computationPicked })
            }
        },
        nSmall (dataset) {
            return sData.getDataValues(dataset.items).length < 30
        },
        importPairedDifference (dataset) {
            const summaryData = sData.calculateDataCheck(dataset.items)
            if (!_.isEmpty(summaryData.errors)) {
                alert(_.head(summaryData.errors))
            } else {
                this.n = summaryData.output.sampleSize
                this.dBar = summaryData.output.mean
                this.sd = summaryData.output.stdDev
            }
        },
        selectVerify (datasets) {
            if (this.choiceData.pick.sample === 'small') {
                return _.filter(datasets, (dataset) => {
                    return this.nSmall(dataset)
                }).length > 0
            }
            return true
        },
        selectFilterSecond (dataset, first) {
            if (this.choiceData.pick.sample === 'small') {
                return this.nSmall(first) || this.nSmall(dataset)
            }
            return true
        },
        importDatasets (first, second) {
            const dataFirst = sData.calculateDataCheck(first.items)
            const dataSecond = sData.calculateDataCheck(second.items)
            if (!_.isEmpty(dataFirst.errors)) {
                alert(_.head(dataFirst.errors))
            } else if (!_.isEmpty(dataSecond.errors)) {
                alert(_.head(dataSecond.errors))
            } else {
                this.x̄1 = dataFirst.output.mean
                this.x̄2 = dataSecond.output.mean
                this.n1 = dataFirst.output.sampleSize
                this.n2 = dataSecond.output.sampleSize
                this.s2 = dataFirst.output.stdDev
                this.s1 = dataSecond.output.stdDev
            }
        },
        onSubmit () {
            var { allData } = this
            this.updateCompType() // Two Dependent only
            const output = hypothesisData.calculateTwoMean(this)

            if (!output) {
                console.log('Calculation Error')
            } else {
                _.assign(this, output.data)
                const graphOutput = normalData.calculateGraphData(this, 0, 1)
                _.set(this, 'graphData', graphOutput)

                _.set(allData, `${this.getComputationName}`, { input: output.input, output: output.data })
                localStore.setStoreItem('hypothesis-data', allData)
                this.showModal()
            }
        },
    },
}
</script>
